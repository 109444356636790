.table 
{

    box-shadow: 0px 0px 1px 0px var(--ion-color-medium);
    border-radius: 7px;
    background-color: var(--ion-background-color);
    transition: all 0.1s ease-in;
    overflow: hidden;

	.table-header {
		background-color: var(--ion-color-primary);
		color: var(--color-default-text-contrast);
		font-weight: 600;

        font-size: 14px;

        border-top-left-radius: 8px;
		border-top-right-radius: 8px;

        ion-col {
            padding: 8px 4px;
            
            text-align: left;

            &:first-child {
                padding-left: 16px;
            }

            &:last-child {
                padding-right: 16px;
            }
        }
	}

    .table-body,
    .last-child-fix
    {
        
        box-shadow: 0px 0px 1px 0px var(--ion-color-medium) !important;
    }

	.table-body {
		min-height: 50px;
		border-top: 2px solid var(--ion-background-color);

		text-align: left;
		font-size: 12px;

        background: var(--ion-background-color);

        // border-radius: 8px;



        // &:nth-child(even)
        // {
        //     background: white;
        // }

		//background-color: var(--color-background-grey);

        &.table-inner-header
        {
            background-color: var(--ion-color-light-shade);
            font-weight: bolder;
            border: 0!important;
            font-size: 14px;
        }

        ion-button {
            //padding-left: 4px;
            //padding-right: 4px;
        }

        ion-icon {
            font-size: 20px;
        }

        ion-col {
            @extend .flex-align-center;

            padding: 0 5px;

        }

        &.table-no-padding
        {
           ion-col
           {
                &:first-child 
                {
                    padding-left: 0;
                }

                &:last-child 
                {
                    padding-right: 0;
                }
           }
        }

        &:not(.table-no-padding)
        {
            ion-col
            {

                &:first-child 
                {
                    padding-left: 16px;
                }
    
                &:last-child 
                {
                    padding-right: 16px;
                }
            }
        }

        .table-col-multiline {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

			span {
				color: var(--color-text-light-grey);

                ion-icon {
                    margin-left: 5px;
                }
			}
		}
	}

    &.loading
    {
        background-color: transparent !important;

        padding-top: 32px;
        display: flex;
        box-shadow: none !important;
    }
}