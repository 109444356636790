ion-modal
{
    --border-radius: 5px;
    --height: calc(100% - 200px); 
    --width: calc(100% - 200px);
    --max-width: 1366px;

    ion-content
    {
        &.padding-horizontal
        {
            --background: var(--ion-background-color) !important;
        }
    }

    ion-header
    {
        ion-toolbar
        {
            --background: var(--ion-background-color) !important;
        }
    }

    &.small
    {
     
        --max-width: 720px;
        --max-height: 500px;
        --min-height: 300px;


        &.high
        {
            --max-height: 800px;
        }
    } 
    
    &.medium
    {
     
        --max-width: 960px;
        --max-height: 600px;

        &.high
        {
            --max-height: 800px;
        }
    }

    &.full
    {
        --max-width: 100%;
        --max-height: 100%;    
        --width: 100%;
        --height: 100%;
        --border-radius: 0;
    }
}

ion-modal.modal-datetime {
    &::part(content) {
        --width: 300px;
        --height: 393px;
    }
}

@media only screen and (min-width: 1200px) {

    ion-modal
    {
        &.full
        {
            --max-width: 1920px;
            --max-height: calc(100% - 100px);  
            --width: calc(100% - 64px);
        }
    }
}

@media only screen and (max-width: 560px),
            screen and (max-height: 600px) {

    ion-modal
    {
        --max-width: 100% !important;
        --max-height: 100% !important;  
 
        --width: 100% !important;
        --height: 100% !important;
        
    }
}