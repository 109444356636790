ion-card 
{ 
    border-radius: 8px;
    padding: 12px;

    &.card-content-md 
    {
        line-height: initial !important;
    }

    &.chart
    {

    }

    ion-card-header
    {
        ion-card-title
        {            
            color: var(--ion-color-dark);
            font-weight: bold !important;
        }
    }

    ion-card-content
    {
        h1
        {
            width: 100%;
            font-size: 50px !important;
            color: var(--ion-color-dark);
            font-weight: bold !important;
        }
    }
}