ion-input,
ion-textarea,
ion-select.outline 
{    
    &.currency
    {
        &::before 
        {
            position: absolute;
            content:"€";
        }

        &.right
        {
            text-align: end; 
            padding-right: 10px !important;

            &::before
            {
                right: 8px;
            }
        }
    }

    &.percentage
    {
        &::before 
        {
            position: absolute;
            content:"%";
        }

        &.right
        {
            text-align: end; 
            padding-right: 10px !important;

            &::before
            {
                right: 8px;
            }
        }
    }
    
    
    //--background: var(--color-background-grey)!important;

    //--color: var(--default-text-color-dark);

    --padding-start: 12px!important;
    --padding-end: 12px!important;

    border: solid 1px var(--ion-color-border);

    border-radius: 5px;

    font-weight: 600!important;
    font-size: 14px!important;
    
    padding: 10px;
   
    input 
    {
        height: 41px!important;
    }

    &[disabled] 
    {
        //--background: var(--color-text-light-grey);
        border: none;
    }

    &.ng-invalid 
    {
        &.ng-touched {
            border-color: var(--ion-color-danger);
        }
    }

    &[button] 
    {
        input {
            cursor: pointer;
        }
    }

    ion-icon 
    {
        position: absolute;
        right: 14px;

        color: "#EFEFEF";
    }

    // select fix from floating stacked
    &::part(icon) 
    {
      transform: translate3d(0px, 0px, 0px);
    }

    &.select-expanded::part(icon) 
    {
        transform: translate3d(0px, 0px, 0px) rotate(180deg);
    }

}

// /* Set the width to the full container and center the content */
// ion-select {
    
//     // max-width: 160px !important;
//     // justify-content: center;
// }

// /* Set the flex in order to size the text width to its content */
// ion-select::part(placeholder),
// ion-select::part(text) {
// //   flex: 0 0 auto;
// }


ion-searchbar
{

    --box-shadow: none !important;
    --background: var(--ion-color-light) !important;

    --color: var(--color-default-text) !important;
    --icon-color: var(--color-default-text) !important;
}

ion-radio-group
{
    ion-item
    {
        ion-grid
        {
            margin-inline: inherit !important;
            margin-top: -5px;

            ion-label
            {
                font-size: 16px !important;
            }

            ion-item
            {
                margin: 0;
            }

        }

        ion-radio
        {
            margin-inline-end: 16px;
        }
        
        ion-radio 
        {
            // --border-radius: 4px;
            // --inner-border-radius: 4px;
          
            --color: var(--ion-color-light);
            --color-checked: var(--ion-color-primary);

            &.ios 
            {
              width: 20px;
              height: 20px;
            
              border: 2px solid var(--ion-color-light);
              border-radius: 4px;
            }

            &.radio-checked.ios 
            {
              border-color: var(--ion-color-primary);
            }
        }
          
         
    }
}