cdk-virtual-scroll-viewport 
{
    display: block;
    min-height: 200px;
    height: 100%;
    width: 100%;    
    
    &.padding-horizontal
    {
        padding-left: var(--content-margin) !important;
        padding-right: var(--content-margin) !important;
        background: var(--ion-color-light) !important;

    }

    &.cdk-virtual-scroll-content-wrapper
    {
        // position: relative;  
        
    }

    &.popover
    {
        height: 450px !important;

        --backdrop-opacity: 0 !important;
        --background: var(--ion-background-color) !important;
    }

}