.flex-display {
	display: flex;
}

.flex-center {
	@extend .flex-display;
	align-items: center;
	justify-content: center;
}

.flex-justify-start {
	@extend .flex-display;
	justify-content: flex-start;
}

.flex-justify-end {
	@extend .flex-display;
	justify-content: flex-end;
}

.flex-justify-center {
	@extend .flex-display;
	justify-content: center;
}

.flex-align-top {
	@extend .flex-display;
	align-items: flex-start;
}

.flex-align-bottom {
	@extend .flex-display;
	align-items: flex-end;
}

.flex-align-center {
	@extend .flex-display;
	align-items: center;
}

.no-margin-bottom {
	margin-bottom: 0!important;
}
