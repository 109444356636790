ion-toast
{
	--background: var(--ion-color-warning-tint);
	// --background: var(--background-page-gradient);

	&::part(container)
	{
		padding: 6px 0;
	}

}