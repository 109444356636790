ion-grid 
{
	&.fullsize-grid 
    {
		height: 100%;

		>ion-row {
			height: 100%;

			>ion-col {
				height: 100%;
			}
		}
	}
    
    &.table.height-toolbar-1
    {
        min-height: calc(100vh - 196px);
    }

    &.table.height-toolbar-2
    {
        min-height: calc(100vh - 304px);
    }

    &.table.height-toolbar-3
    {
        min-height: calc(100vh - 386px);
    }

    padding: 0;

    > ion-row {
        padding: 0;

        &[button] {
            cursor: pointer;
        }
        
        > ion-col {
            padding: 0;

            &:last-child {
                ion-item {
                    margin-right: 0;
                }
            }
            
            ion-item {
                margin-right: 16px;
            }
        }


    }

    ion-row
    {
        &.with-button-opacity
        { 
        
            ion-button
            {
                opacity: 0.3;
                transition: all .2s ease-in;
            }
            &:hover
            {
                ion-button:not(.button-disabled)
                {
                    opacity: 1;
                }
            }
        }

        ion-col
        {
            &.buttons-size
            {
                max-width: 60px !important;
            } 
            
            &.buttons-size-2
            {
                max-width: 90px !important;
            } 

            &.buttons-size-3
            {
                max-width: 130px !important;
            } 
            
            &.icon-status-size
            {
                max-width: 42px !important;
            } 
            &.icon-status-size-2
            {
                max-width: 80px !important;
            }
        }
    }
}