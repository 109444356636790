.full
{
    width: 100%;
}

.display-flex
{
    display: flex;
}

ion-icon 
{
    pointer-events: none;
}

span > ion-icon
{
    @extend .cursor-pointer;
}

.cursor-pointer
{
    cursor: pointer;
}

.cursor-help
{
    cursor: help;
}

.no-padding-left
{
    padding-left: 0 !important;
}

.no-padding-right
{
    padding-right: 0 !important;
}

.text-bold{
    font-weight: bold;
}