ion-toolbar 
{
    --background: var(--ion-background-color);

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    color: var(--ion-background-color);

    ion-title:has(ion-text):has(p)
    {
        p
        {
            font-size: 0.8rem;
            margin: 0;
        }
    }

    &.clear
    {
        --background: transparent;
        color: var(--ion-color-dark);
        font-weight: bold;

        // workaround to remove shadows on the cards if are under the toolbar
        width: calc(100% + 10px);
        margin-left: -5px;
        padding: 5px;
        padding-right: 0;

        ion-title
        {
            padding-inline: 0px !important;
        }
    }

    &.tabs
    {
        ion-segment
        {
            display: block;
            text-align: start;

            ion-segment-button
            {
                --background: var(--ion-background-color);
                --background-checked: var(--ion-color-primary);
                --color: var(--ion-color-primary);
                --color-checked: var(--ion-background-color);
                --ripple-color: transparent;
                --border-width: 0px;
                // --border-style: solid;
                // --border-color: var(--ion-color-primary);
                --indicator-height: 0;
                display: inline-block;
                border-radius: 12px;
                margin-right: 6px;
                min-height: 20px;
                min-width: 140px;
                font-weight: bold;
                text-transform: capitalize;
                box-shadow: 0px 0px 6px -6px var(--ion-color-dark);
            }
        }
    }

    &.filters
    {

        ion-col
        {
            display: inline-flex;

            &:last-child
            {
                max-width: 50px;
            }
                
            ion-searchbar
            {
                margin: 16px 8px 8px 0;
            }

            ion-item
            {
                margin-top: 4px; 
                overflow-x: hidden;

                ion-label
                {
                    font-size: 10px !important;
                    font-weight: normal;
                    margin-bottom: 2px !important;
                }

                /* one item */
                &:first-child:nth-last-child(1) {
                    width: 100%;
                }
                
                /* two items */
                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(2) ~ ion-item {
                    width: 50%;
                }
                
                /* three items */
                &:first-child:nth-last-child(3),
                &:first-child:nth-last-child(3) ~ ion-item {
                    width: 33.3333%;
                }
                
                /* four items */
                &:first-child:nth-last-child(4),
                &:first-child:nth-last-child(4) ~ ion-item {
                    width: 25%;
                }
            }
        }


    }
}

ion-content 
{


    ion-toolbar 
    {
        margin-bottom: 16px;
        border-radius: 8px;
        padding: 12px;

        ion-searchbar 
        {
            --border-radius: 8px!important;
        }
    }
}

ion-header,
ion-footer
{
    ion-toolbar
    {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

ion-footer
{
    ion-toolbar
    {
        --background: none;
    }
}