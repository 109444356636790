text-editor-quill
{
    min-width: 340px;
    .ql-showHtml
    {
        width: 47px !important;
        color: #444444;
    }
    .ql-formats
    {
        
        // color: var(--ion-text-color);

        button,
        span
        {
            &:focus
            {
                outline: none!important;
            }
        }
    }
    .ql-tags
    {
        width: 50px !important;
        &::before
        {
            display: inline-block;
            line-height: 22px;
            margin-left: 3px;
            content: "tags";
            position: absolute;
        }
        
        .ql-picker-item
        {
            &:focus
            {
                outline: none!important;
            }
            &::before
            {
                content: attr(data-value);
            }
        }
        // 
    }

    .ql-custom
    {
        display: block;
        position: absolute;
        /* width: 100%; */
        /* height: 100%; */
        visibility: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--ion-background-color);
        
        color: var(--ion-text-color);

        textarea
        {
            width: 100%;
            // height: calc(100% - 5px);
            min-width: 100%;
            // min-height: calc(100% - 5px);
            border-color: transparent;
            background-color: transparent; 
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
        }

    }

    .ql-editor
    {
        color: var(--ion-text-color) !important;
        background-color: var(--ion-background-color);

        &.ql-blank::before
        {
            color: var(--ion-text-color) !important;
        }
    }

    .ql-toolbar
    {
        background-color: rgba(243,243,243, 0.54);
    }

    .ql-tooltip input[type=text]
    {
        background: transparent !important;
    }

    textarea
    {
        &#quill-editor-area
        {        
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
        }
    }

    .disabled
    {
        opacity: 0.8;
        .ql-toolbar::before
        {
            content: '';
            position: absolute;
            display: block;
            height: 60px;
            width: 100%;
            left: 0;
            right: 0;
            margin-top: 0px;
            z-index: 9999;
            // background-color: red;
        }
    }
}