ion-header 
{
	ion-title,
	ion-buttons
	{
		color: var(--ion-color-dark);
		--color: var(--ion-color-dark);

		&[slot="end"]
		{
			padding-right: 10px;
		}
	}

    &.large ion-toolbar 
	{
		
        height: 60px;
        // --background: var(--ion-background-color) !important;  
        --background: var(--ion-color-light) !important;

    //    ion-icon.logo
    //     {                    
    //         width: 100px;
    //         height: 60px;
	// 		padding: 10px 16px 10px 0px;
    //     }      
        
        // div.logo
        // {                    
        //     width: 100px;
        //     height: 60px;
		// 	padding: 10px 16px 10px 0px;
        //     background: url('^./assets/project/project_logo.svg') no-repeat center;
        //     margin-right: 16px;
        // }
	}
	
	ion-toolbar
    {
        --background: var(--ion-color-light) !important;  
		
		padding-left: 16px;
		padding-right: 16px;      
    }

    &::after 
    {
        //remove border do ion-header
        display: none;
    }

}

body.dark
{
    ion-header 
    {
        div.logo
        {
            background: url('^./assets/project/logo_white.svg') no-repeat center;
        }
    }
}