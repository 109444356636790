ion-menu {
	&.sidemenu {

        --side-width: 300px!important;
        
        --background: var(--ion-color-primary);

        --max-width: 300px!important;
        
        border-inline-end: 1px solid var(--ion-color-light-shade);

        ion-content {
            --background: transparent;

            --padding-start: 8px;
            --padding-end: 8px;
            --padding-top: 8px;
            --padding-bottom: 8px;
        }


        ion-list {

            background: transparent;;
            padding-top: 0;

            ion-list-header 
            {
                color: var(--ion-color-primary-contrast) !important;

                .logo
                {
                    height: 50px;
                }
                
                ion-item
                {
                    
                    --background: transparent !important;
                }
            }

            &#sidemenu-list 
            {
                ion-list-header
                {
                    
                    text-transform: uppercase; // ???
                    
                    &.card 
                    {
                        padding: 16px 0;
                        border-radius: 8px;
                        margin-bottom: 8px;
            
                        ion-item 
                        {
                            --background: var(--ion-color-primary);

                            ion-label 
                            { 
                                margin-bottom: 0;
                                color: var(--color-menu-text);
                                
                                h1 
                                {
                                    font-size: 16px;
                                    font-weight: bold;
                                }

                                p 
                                {
                                    font-size: 10px;
                                    color: var(--color-menu-text);
                                }
                            }

                            ion-button
                            {
                                &.selected 
                                {
                                    // --background: rgba(var(--color-menu-background-rgb), 0.2);
                    
                                    ion-icon 
                                    {
                                        color: var(--ion-color-light-shade);
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }

        ion-item {
            --padding-start: 10px;
            --padding-end: 10px;
            --background: transparent;
            
            --color: var(--ion-color-primary-contrast);
            
            border-radius: 4px;
            margin-top: 0;

            ion-icon {
                color: var(--ion-color-primary-contrast);
            }

            &.selected {
                --background: rgba(var(--ion-color-medium-rgb), 0.2);

                ion-icon {
                    color: var(--ion-color-primary-contrast);
                  }
            }
        }
	}
}