ion-item {

    --padding-start: 0;

    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    --background-activated-opacity: 0;
    --show-full-highlight: 0;

    --ripple-color: transparent;
    --background-activated: transparent;
    //--highlight-background: transparent;

    --highlight-color-focused: none;

    //--highlight-height: 0;

    --inner-padding-end: 0;
    --inner-border-width: 0;

	--border-width: 0;
	
    margin-top: 12px;	
	
    p 
	{
        margin: 0;
    }

    > ion-label 
	{
        text-transform: none;
        font-size: 16px !important;
        margin-bottom: 10px!important;
    }


    //.info-item {
        //background-color: #f5f5f5;
        // width: 100%;
        // padding: 12px 17px;
        // font-weight: 600;
        // font-size: 14px;

        border-radius: 5px;
    //}
   
	&:has(ion-select)
	{
		
		overflow: hidden;
	}
}



form
{
	ion-item
	{
		// align-items: flex-start;

		&.ion-touched
		{
			ion-label
			{
				color: var(--ion-color-text) !important;
			}
		}

		.item-label-stacked
		{
			--padding-top: 7px !important;
	
		}
		
		ion-label
		{
			color: var(--ion-color-text) !important;
			padding-top: 3px; // ????
			font-weight: bold;
			transform: none !important;
			
			&[required]:after
			{
				content: '*';
				color: var(--ion-color-danger);
				padding-left: 2px;
			}

			&[required="false"]:after
			{
				opacity: 0;
			}
			
		}
		

		// rewrite disable rule for inputs
		&.disabled,
		&.item-disabled,
		&.item-select-disabled,
		&.item-interactive-disabled,
		&.item-interactive-disabled input,
		.select-disabled
		{
			opacity: .9 !important;

			ion-label
			{
				opacity: 1 !important;
			}
		}

		
	}
}

ion-item-divider
{
	padding-left: 0;
	ion-label
	{
		
		margin-top: 48px !important;
		font-size: 20px !important;
		text-transform: uppercase !important;
		color: var(--color-default-text) !important;
	}
}
