/* REMOVE AUTO FILL NOS INPUTS COLOCADO PELO CHROME */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-transition: background-color 999999s ease-out;
	-webkit-text-fill-color: black !important;
}


.fix-filename
{
	padding: 15px 0 0 12px;
	display: inline-block;
	max-width: calc(100% - 20px);
	position: absolute;

	@extend .input-text-ellipse
}

.input-text-ellipse
{
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}