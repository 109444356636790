ion-list 
{
    line-height: initial !important;

    // ion-item {
    //     padding-bottom: 8px;

    //     &:last-child {
    //         padding-bottom: 0px;
    //     }
    // }

    ion-list-header
    {
        padding: 0 6px;
        ion-label
        {
            text-transform: none;
            font-size: 16px !important;
            // margin-bottom: 10px !important;
            font-weight: bold;
        }
    }
}