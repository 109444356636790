google-map
{
    &.small
    {
        .map-container
        {
            min-height: 300px;
        }
    }

    &.medium
    {
        .map-container
        {
            min-height: 404px;
        }
    }
    .map-container
    {
        min-height: 300px;
        height: 100% !important;
        width: 100% !important;
    }
}

