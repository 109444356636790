ion-alert
{

    .alert-wrapper
    {
        .alert-head
        {
            box-shadow: 0px 0px 1px 0px var(--ion-color-dark);
            margin-bottom: 16px;

            .alert-title
            {
                color: var(--ion-color-primary);
            }
        }

        .alert-message
        {
            min-height: 100px;
            color: var(--ion-text-color);
        }

        .alert-button-group
        {
            padding: 12px 6px;
            button
            {
                background: var(--ion-color-medium-tint);
                color: var(--ion-color-light);
                text-transform: capitalize;
                padding: 8px 16px;
                border-radius: 6px;

                &:last-child
                {
                    background: var(--ion-color-medium);
                    color: var(--ion-color-light);
                }
            }
        }
    }

    &.default
    {
        .alert-wrapper
        {
            --min-width: 400px;
        }
    }

    &.alert-no-message
    {   
        .alert-wrapper
        {
            --min-width: 333px;
            
            .alert-head
            {
                margin-bottom: 0px !important;
            }
            .alert-message
            {
                display: none;
            }
        }
        
    }

    &.medium
    {
        .alert-wrapper
        {
            
            --width: 100%;
            --max-width: 560px;
            --height: 100%;
            --max-height: 600px;

            .alert-radio-group
            {
                height: 100%;
                max-height: 100%;
            }
        }

   
    }

    &.recover
    {
        .alert-wrapper
        {
            --min-width: 400px;

            .alert-input-group input
            {
                border: 1px solid var(--ion-color-medium-tint) !important;
                border-radius: 8px;
                padding: 12px;
        
            }
        }
    }

    &.alert-textarea
    {
        .alert-wrapper
        {
            .alert-message
            {
                min-height: 0px;
            }

            .alert-input-group:has(textarea)
            {
                padding-top: 0px;
                
                textarea
                {
                    min-height: 144px !important;
                    border: 1px solid var(--ion-color-medium-tint) !important;
                    border-radius: 16px;
                    padding: 12px !important;

                }
            }
        }
        
    }


}    

@media only screen and (max-width: 560px),
            screen and (max-height: 600px) {

    ion-alert
    {

        .alert-wrapper
        {
            --max-width: 100% !important;
            --max-height: 100% !important;  

            --min-width: 300px;

            --width: 100% !important;
            --height: 100% !important;
        }
        
    }
}