ion-popover
{
	--min-width: 100px !important;
	--width: auto !important;
	--max-width: 500px !important;

	color: var(--color-default-text-shade) !important;

	&.select-search
	{
		--width: 100% !important;
		--max-width: 540px !important;
		--height: 100% !important;
		--max-height: 510px !important;
		--backdrop-opacity: 0 !important;

		ion-content,
		ion-header > ion-toolbar
		{
			ion-searchbar
			{
				--box-shadow: 0px 0px 1px 0 var(--ion-color-dark) !important;
				--background: var(--ion-background-color) !important;
			}
			
			--background: var(--ion-background-color) !important;
		}
	}

}

ion-select-popover
{

	ion-list
	{
		padding: 0 !important;
	}

	.select-interface-option
	{
		--padding-start: 16px;
		--padding-end: 16px;
		--padding-top: 6px;
		margin: 0;
		border-radius: 0;
	}
}

ion-popover
{
     ion-item
    {
		--background: transparent;

		&:hover,
		&.item-radio-checked
		{
			color: var(--ion-color-primary) !important;
			--color: var(--ion-color-primary) !important;
		}
    }

	&.custom
	{

		&.medium
		{
			--min-width: 200px !important;
			// ion-select-popover
			// {
			// 	width: 200px;
			// }
		}
	}
}

// fix popover on chrome 114
// https://stackoverflow.com/questions/76376324/ion-select-popover-not-working-chrome-114
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}