
ion-button
{    
    
    min-height: 36px !important;
    text-transform: none !important;

    --border-radius: 6px !important;
    --padding-start: 10px !important;
    --padding-end: 10px !important;

    &[fill="solid"]
    {
        min-height: 40px !important;
        
        ion-icon
        {
            font-size: 1.6em !important; 
        }
    }   
     
    ion-icon
    {
        // margin: 0 2px !important;
        // margin-inline-end: 0 !important;

    }
}

ion-datetime-button
{
    &::part(native)
    {
        padding: 11px;
        background-color: transparent;
        outline: 1px solid rgba(0,0,0,0.18);
        font-size: 14px;
        font-weight: bold;
        margin-right: 16px;
        border-radius: 4px;
        height: 40px;

        ::slotted([slot=time-target])
        {
            margin-left: 12px!important;
        }
    }

    &.only-date
    {     
        width: 100%;
        height: 44px;

        &::part(native)
        {
            width: 100%;
            text-align: left;
        }
            
        // ::slotted([slot=time-target])
        // {
        //     background-color: red !important;
        // }
        // ::slot(time-target)
        // {
        //     background-color: red !important;
        // }
        // ::slot(name=time-target)
        // {
        //     background-color: red !important; 
        // }
        
    }
}