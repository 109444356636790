ion-content
{    
    --content-margin: 32px;
    --background: var(--ion-color-light);

    &.padding-horizontal
    {
        --padding-start: var(--content-margin) !important;
        --padding-end: var(--content-margin) !important;
        --background: var(--ion-color-light) !important;
    }

    .cdk-virtual-scroll-content-wrapper > ion-toolbar.clear,
    >ion-toolbar.clear
    {
        position: sticky;
        top: -16px;
        border-radius: 0;
        z-index: 99;
        --background: var(--ion-color-light);
    }

    cdk-virtual-scroll-viewport 
    {
        height: 100%;
        width: 100%;    
        
        &.padding-horizontal
        {
            padding-left: var(--content-margin) !important;
            padding-right: var(--content-margin) !important;
            background: var(--ion-color-light) !important;

        }

        .cdk-virtual-scroll-content-wrapper
        {
            position: relative;  
            
        }

    }

    >ion-grid:not(.table)
    {        
        min-height: calc(100% - 116px) !important;

    }
    
}