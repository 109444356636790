// --backdrop-opacity	Opacity of the backdrop
// --background	Background of the loading dialog
// --height	Height of the loading dialog
// --max-height	Maximum height of the loading dialog
// --max-width	Maximum width of the loading dialog
// --min-height	Minimum height of the loading dialog
// --min-width	Minimum width of the loading dialog
// --spinner-color	Color of the loading spinner
// --width

ion-loading
{

    &.ion-loading-default
    {
        --min-height: 170px;
        --background: var(--ion-background-color);


        .loading-wrapper
        {
            color: var(--color-default-text) ;
        }
    }
}
