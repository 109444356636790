
ion-footer
{
    background: var(--ion-background-color);

    color: var(--ion-text-color);

    ion-toolbar
    {        
        color: var(--ion-text-color);
    }

    &.default
    {

        background: var(--ion-color-light);
    }
    
}